import React from 'react'
import { Button, Divider, Select } from 'antd'
import { Product, useBasketStateDispatch } from '../../contexts'
import { Count } from '../Count'
import { DeleteOutlined } from '@ant-design/icons'
import './BasketProduct.css'
import { useTranslation } from 'react-i18next'

export interface BasketProductProps {
    count: number
    product: Product
    imgSize?: string
    grind: string;
}

const BasketProduct = ({ product, imgSize, count, grind }: BasketProductProps) => {
    const { t } = useTranslation()
    const dispatch = useBasketStateDispatch()

    const removeProduct = () => {
        dispatch({
            type: 'removeProduct',
            payload: product.id,
        })
    }

    const grindingOptions = [
        { label: t('grinding.inGrains'), value: 'inGrains' },
        { label: t('grinding.frenchPressLarge'), value: 'frenchPressLarge' },
        { label: t('grinding.dripCoffeeMakerLarge'), value: 'dripCoffeeMakerLarge' },
        { label: t('grinding.kemexLarge'), value: 'kemexLarge' },
        { label: t('grinding.puroverMiddle'), value: 'puroverMiddle' },
        { label: t('grinding.aeropressMiddle'), value: 'aeropressMiddle' },
        { label: t('grinding.geyserCoffeeMakerMiddle'), value: 'geyserCoffeeMakerMiddle' },
        { label: t('grinding.espressoRoughMiddle'), value: 'espressoRoughMiddle' },
        { label: t('grinding.espressoThinSmall'), value: 'espressoThinSmall' },
        { label: t('grinding.dzezvaSmall'), value: 'dzezvaSmall' },
        { label: t('grinding.cupMiddle'), value: 'cupMiddle' },
    ]

    const setCount = (count: number) => {
        if (count <= product.balance) {
            if (count < 1) {
                return
            }

            dispatch({
                type: 'updateProduct',
                payload: {
                    id: product.id,
                    count,
                    grind,
                },
            })
        }
    }

    const updateGrind = (grind: string) => {
        dispatch({
            type: 'updateProduct',
            payload: {
                id: product.id,
                count,
                grind: grind,
            },
        })
    }

    return (
        <>
            <div className="BasketProduct">
                <img
                    alt="example"
                    width={imgSize ? imgSize : '50px'}
                    height="100%"
                    src={product.imagePath ?? 'random_coffee_img.png'}
                />

                <div className="BasketProductContent">
                    <h1 className="BasketProductTitle">{product.itemName}</h1>
                    <span className="BasketProductPrice">
                        {count}x{product.price} ֏
                    </span>

                    <Select
                        className="BasketProductGrindSelect"
                        variant="borderless"
                        size="small"
                        defaultValue={grind}
                        style={{
                            width: '200px',
                            textAlign: 'start',
                        }}
                        onChange={updateGrind}
                        options={grindingOptions}
                        dropdownStyle={{
                            width: '100%',
                            maxWidth: '300px',
                        }}
                    />
                </div>

                <div className="BasketProductControls">
                    <Count value={count} setCount={setCount} />
                    <Button
                        className="BasketProductRemoveBtn"
                        type="link"
                        color="gray"
                        icon={<DeleteOutlined />}
                        size={'small'}
                        onClick={removeProduct}
                    />
                </div>
            </div>
            <Divider />
        </>
    )
}

export default BasketProduct
